import React, { useEffect, useState } from "react";
import {
  HorizontalContainer,
  HorizontalLeftAlignedContainer,
} from "../styles/container";
import { H2, TextRegular, TextSmall } from "../styles/text";
import { COLORS } from "../styles/colors";
import {
  HEADER_VERTICAL_SPACING,
  ITEM_HORIZONTAL_SPACING,
  ITEM_VERTICAL_SPACING,
  LINE_HORIZONTAL_SPACING,
  SECTION_VERTICAL_SPACING,
  SIDE_PADDING,
} from "../styles/spacing";
import youtubeLogo from "../assets/images/youtube-logo.png";
import googleLogo from "../assets/images/google-play-store-logo.png";
import appleLogo from "../assets/images/apple-icon.png";
import { getBorderRadius } from "../utils";

export const SupportSidebar = () => {
  const colors = COLORS["light"];

  const [width, setWidth] = useState(0);
  const [collapsed, setCollapsed] = useState(false);

  function getSize() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", getSize);
    if (window.innerWidth < 1000) setCollapsed(true);
    else setCollapsed(false);

    return () => window.removeEventListener("resize", getSize);
  }, [window.innerWidth]);

  return !collapsed ? (
    <div
      style={{
        display: "flex",
        paddingTop: HEADER_VERTICAL_SPACING,
        flexDirection: "column",
        paddingLeft: SIDE_PADDING,
        paddingRight: SIDE_PADDING,
        top: 0,
        position: "sticky",
      }}
    >
      <a
        href="https://play.google.com/store/apps/details?id=com.hopewell.picklers.mobile"
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <HorizontalLeftAlignedContainer
          style={{
            padding: ITEM_HORIZONTAL_SPACING,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: getBorderRadius("small", "rounded rect"),
            borderColor: colors.LIGHT_GRAY,
          }}
        >
          <img src={googleLogo} width={40} height={40} alt="google icon" />
          <div style={{ marginLeft: LINE_HORIZONTAL_SPACING }}>
            <TextRegular>Download from</TextRegular>
            <H2>Google Play</H2>
          </div>
        </HorizontalLeftAlignedContainer>
      </a>
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <a
        href="https://apps.apple.com/us/app/picklers/id6449392308"
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <HorizontalLeftAlignedContainer
          style={{
            padding: ITEM_HORIZONTAL_SPACING,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: getBorderRadius("small", "rounded rect"),
            borderColor: colors.LIGHT_GRAY,
          }}
        >
          <img src={appleLogo} width={40} height={40} alt="apple icon" />
          <div style={{ marginLeft: LINE_HORIZONTAL_SPACING }}>
            <TextRegular>Download from</TextRegular>
            <H2>Apple App Store</H2>
          </div>
        </HorizontalLeftAlignedContainer>
      </a>
      <div style={{ height: ITEM_VERTICAL_SPACING }} />
      <a
        href="https://www.youtube.com/@Picklers-f5z/videos"
        style={{ textDecoration: "none" }}
        target="_blank"
        rel="noreferrer"
      >
        <HorizontalLeftAlignedContainer
          style={{
            paddingLeft: ITEM_HORIZONTAL_SPACING + 4,
            paddingRight: ITEM_HORIZONTAL_SPACING,
            paddingTop: ITEM_VERTICAL_SPACING,
            paddingBottom: ITEM_VERTICAL_SPACING,
            borderStyle: "solid",
            borderWidth: 1,
            borderRadius: getBorderRadius("small", "rounded rect"),
            borderColor: colors.LIGHT_GRAY,
          }}
        >
          <img src={youtubeLogo} width={32} height={32} alt="apple icon" />
          <div style={{ marginLeft: LINE_HORIZONTAL_SPACING + 4 }}>
            <TextRegular>Need Help?</TextRegular>
            <H2>View Picklers Tutorials!</H2>
          </div>
        </HorizontalLeftAlignedContainer>
      </a>
      {/* <div style={{ height: SECTION_VERTICAL_SPACING }} /> */}
      {/* 
      <HorizontalLeftAlignedContainer
        style={{ marginBottom: ITEM_VERTICAL_SPACING }}
      >
        <H2>Get in touch</H2>
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer>
        <TextRegular>
          We are always looking for ways to improve our platform. Let us know if
          you have any suggestions!
        </TextRegular>
      </HorizontalLeftAlignedContainer>
      <HorizontalLeftAlignedContainer
        style={{
          backgroundColor: COLORS["light"].LIGHT_GRAY,
          margin: ITEM_VERTICAL_SPACING,
          padding: ITEM_VERTICAL_SPACING,
        }}
      >
        <div>
          <TextSmall>support@picklers.net</TextSmall>
          <br></br>
          <TextSmall>609-123-4567</TextSmall>
        </div>
      </HorizontalLeftAlignedContainer> */}
      {/* <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
        <TextSmall>
          Questions? See our <Link to="/profile/faq">FAQ</Link>
        </TextSmall>
      </HorizontalContainer>
      <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
        <TextSmall>
          <Link to="/profile/termsofservice">Terms of Service</Link>
          &nbsp;|&nbsp;
          <Link to="/profile/privacypolicy">Privacy Policy</Link>
        </TextSmall>
      </HorizontalContainer>

      <HorizontalContainer style={{ marginTop: ITEM_VERTICAL_SPACING }}>
        <TextSmall>&copy; 2023 Hopewell Opportunity Fund</TextSmall>
      </HorizontalContainer> */}
    </div>
  ) : (
    <></>
  );
};
